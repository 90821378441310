(function ($) {
  'use strict'

  ////////////////////////////////////
  // NO JS TEST
  ////////////////////////////////////
  const el = document.getElementsByTagName('body')[0];
  el.classList.remove('no-js');
  el.classList.add('js');


  // Fetch JSON list of all languages for use in language switcher
  var $langList = [];

  async function fetchLangJson() {
    const response = await fetch('/assets/static/languages_list.json');
    const langlist = await response.json();
    return langlist;
  }

  fetchLangJson().then((langlist) => {
    $langList = langlist;
  });


  // Document ready JS
  $(document).ready(function() {

    // Language select dropdown
    new MsDropdown('#languages');
    const observer = new MutationObserver((mutationList, observer) => {
      for (const mutation of mutationList) {
        if (mutation.type === 'attributes') {
          var selection = mutation.target.innerText;
          navigateToSelection(selection);
        }
      }
    });
    observer.observe(document.querySelector('.ms-options'), {
      attributes: true,
      attributeFilter : ['class'],
      childList: true,
      subtree: true
    });


    // Language select dropdown borders
    $(document).on('click', function() {
      var $options = $('.ms-options');
      if ($options.is(':visible')) {
        $options.closest('.ms-dd').css({
          'border-bottom-left-radius': '0px',
          'border-bottom-right-radius': '0px'
        });
      }
      else {
        $options.closest('.ms-dd').css({
          'border-bottom-left-radius': '9px',
          'border-bottom-right-radius': '9px'
        });
      }
    });


    // Products slider
    var prevArrow = '<button class="slick-prev slick-arrow" type="button"><i class="fa-solid fa-chevron-left" aria-hidden="true"></i></button>';
    var nextArrow = '<button class="slick-next slick-arrow" type="button"><i class="fa-solid fa-chevron-right" aria-hidden="true"></i></button>';
    $('.carousel').slick({
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      arrows: true,
      dots: true,
      autoplay: false,
      prevArrow: prevArrow,
      nextArrow: nextArrow,
      adaptiveHeight: false,
      responsive: [
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
          }
        }
      ]
    });


    // Mobile video slider
    var $slickVideos = false;
    function videoSlider() {
      if ($(window).width() < 768) {
        if (!$slickVideos){
          $('.videos').slick({
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            dots: true,
            autoplay: false,
            prevArrow: prevArrow,
            nextArrow: nextArrow
          });
          $slickVideos = true;
        }
      }
      else if ($(window).width() > 767) {
        if ($slickVideos){
          $('.videos').slick('unslick');
          $slickVideos = false;
        }
      }
    };

    $(document).ready(function(){
      videoSlider();
    });

    $(window).on('resize', function(){
      videoSlider();
    });


    // Product table
    $('.product-table').find('.product-tabs div:visible').addClass('table-active');
    $('.product-table').find('.product-tabs div:visible').attr('aria-hidden', 'false');
    $('.product-table .product-tabs-menu li').on('click', function() {
      $(this).focus();
      var $clickId = $(this).attr('id');

      if (!attrMatches($clickId).is(':visible')) {
        $('.table-active').hide();
        $('.table-active').attr('aria-hidden', 'true');
        $('.product-tabs div').removeClass('table-active');
        attrMatches($clickId).show();
        attrMatches($clickId).attr('aria-hidden', 'false');
        attrMatches($clickId).addClass('table-active');
      }
    });
    $('.product-tabs-menu').on('keydown', keyboardHandler);


    // Mobile product table accessibility
    if ($(window).width() < 768) {
      $('.product-tabs-menu > h3').attr('tabindex', '1');
      $('.product-tabs-menu > h3').attr('aria-controls', 'tabs');
      $('.product-tabs-menu > h3').attr('role', 'button');
      $('.product-tabs-menu > h3').on('keydown', keyboardHandler);
    }


    // Mobile product table click behavior
    $('.product-tabs-menu > h3').on('click', function() {
      if ($(window).width() < 768) {
        $(this).next('ul').slideToggle();
        $(this).closest('.product-tabs-menu').toggleClass('product-menu-open');
        $(this).next('ul').attr('aria-hidden', function(index, attr){
          return attr == 'false' ? 'true' : 'false';
        });
      }
    });


    // Keyboard accessibility helper function
    function keyboardHandler(event) {
      var target = event.target;
      var which = event.which;

      if (which === 13) { // ENTER
        event.stopPropagation();
        event.preventDefault();
        target.click();
      }
    }


    // Attribute matching helper function for table JS
    function attrMatches(id) {
      var $tableClass = $('.product-table').find(`[data-click-target='${id}']`);
      return $tableClass;
    }


    // Language dropdown nav helper function
    function navigateToSelection(selection) {
      var path = window.location.pathname;

      if ($langList.en.includes(selection) && path != '/') { // English
        window.location.href='/';
      }
      else if ($langList.fr.includes(selection) && path != '/fr/') { // French
        window.location.href='/fr/';
      }
      else if ($langList.de.includes(selection) && path != '/de/') { // German
        window.location.href='/de/';
      }
      else if ($langList.it.includes(selection) && path != '/it/') { // Italian
        window.location.href='/it/';
      }
      else if ($langList.es.includes(selection) && path != '/es/') { // Spanish
        window.location.href='/es/';
      }
      else if ($langList.pl.includes(selection) && path != '/pl/') { // Polish
        window.location.href='/pl/';
      }
    }

  });

}(jQuery));
